import { type FC } from "react";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";

import { captureRemixErrorBoundaryError } from "@sentry/remix";

import ErrorScreen from "~/components/screens/error";

import GoogleAnalytics from "./components/GoogleAnalytics/GoogleAnalytics";
import { CLIENT_CONFIG } from "./config";

export const ErrorBoundary: FC = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <ErrorScreen />;
};

export default function App(): JSX.Element {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="app-version" content={CLIENT_CONFIG.versionApp} />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <GoogleAnalytics />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
