import React, { type FC } from "react";

import { Image } from "@shopify/polaris";

import publicFiles from "~/public";

const ErrorScreen: FC = () => {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Image
        source={publicFiles.assets.images.error.ErrorPageIconWEBP}
        alt="error-image"
        style={{
          width: "100px",
          alignSelf: "center",
          margin: "auto",
          filter: "opacity(0.2)",
        }}
      />
    </div>
  );
};

export default React.memo(ErrorScreen);
