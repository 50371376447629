import { type FC } from "react";

import { CLIENT_CONFIG } from "~/config";

const GoogleAnalytics: FC = () => {
  if (!CLIENT_CONFIG.gaTrackingId) {
    //eslint-disable-next-line no-console
    console.error("Google Analytics ID is turned off");
    return;
  }

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${CLIENT_CONFIG.gaTrackingId}`}
      />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${CLIENT_CONFIG.gaTrackingId}', {
                page_path: window.location.pathname
              });
            `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
